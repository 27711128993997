import * as React from 'react'
import { useContext } from 'react'
import { motion } from 'framer-motion'
import styled, { css, ThemeContext } from 'styled-components'
import { themes } from '../../../base'
import * as Icon from '../../../design-system/components/Icons'

const Wrap = styled(motion.a)`
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: block;
  background: white;
  border-radius: 100px;
  padding: 20%;
  ${props =>
    props.type === 'noBg' &&
    css`
      background: transparent;
    `}
`

export function DownloadButton({ theme, fileUrl, type, ...props }) {
  const selectedTheme = useContext(ThemeContext) || themes[theme]
  return (
    <Wrap
      href={fileUrl}
      target="_blank"
      whileTap={{
        scale: 0.75,
      }}
      selectedTheme={selectedTheme}
      type={type}
    >
      <Icon.Download fill={selectedTheme.color.framerPurple} />
    </Wrap>
  )
}

DownloadButton.defaultProps = {
  theme: 'light',
  fileUrl: '',
  type: 'normal',
}

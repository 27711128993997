import * as React from 'react'
import styled, { css, ThemeContext } from 'styled-components'
import { useContext, createElement } from 'react'
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps'
import { compose, withProps } from 'recompose'
import { themes } from '../../../base'
import { PlaceholderState } from '../../../base/PlaceholderState'

const Wrap = styled.div`
  height: 100%;
  overflow: hidden;
  border-radius: 12px;
`

export function Map({ googleAPIKey, ...props }) {
  if (!googleAPIKey) {
    return createElement(PlaceholderState, {
      title: 'No googleAPIKey',
      label: 'Add your googleAPIKey',
    })
  }
  return (
    <Wrap>
      {compose(
        withProps({
          googleMapURL:
            'https://maps.googleapis.com/maps/api/js?key=' +
            googleAPIKey +
            '&v=3.exp&libraries=geometry,drawing,places',
          loadingElement: <div style={{ height: `100%` }} />,
          containerElement: <div style={{ height: `100%` }} />,
          mapElement: <div style={{ height: `100%` }} />,
        }),
        withScriptjs,
        withGoogleMap
      )(props => (
        <GoogleMap
          defaultZoom={15}
          defaultCenter={{ lat: 37.520396, lng: 127.048831 }}
        >
          {props.isMarkerShown && (
            <Marker
              position={{ lat: 37.520396, lng: 127.048831 }}
              onClick={props.onMarkerClick}
            />
          )}
        </GoogleMap>
      ))()}
    </Wrap>
  )
}

Map.defaultProps = {
  theme: 'light',
  googleAPIKey: '',
}

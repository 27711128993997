import * as React from 'react'
import styled, { css, ThemeContext } from 'styled-components'
import { useContext } from 'react'
import { themes } from '../../../base'
import { isMobile } from '../../../base/utils'
import * as Icon from '../../../design-system/components/Icons'
import * as System from '../../../design-system'

export function Footer({ siteTitle, theme, ...props }) {
  const selectedTheme = useContext(ThemeContext) || themes[theme]

  const Wrap = styled.footer`
    background: ${selectedTheme.color.contentBg};
    padding-top: 60px;
    padding-bottom: 97px;
    @media (max-width: ${selectedTheme.breakpoint.mobile}px) {
      padding: 40px 20px 40px 20px;
    }
  `
  const Content = styled.div`
    max-width: 1080px;
    margin: 0 auto;

    font-family: ${selectedTheme.font.en.type1};
    color: ${selectedTheme.color.secondaryText};
    font-size: 16px;
    font-weight: 300;
  `
  const Text0 = styled.span``
  const Text1 = styled.span`
    color: ${selectedTheme.color.framerPurple};
    float: right;
  `
  const Link0 = styled.a`
    vertical-align: middle;
  `
  const Link1 = styled.a`
    vertical-align: middle;
  `

  return (
    <Wrap>
      <Content>
        <Text0>Framer Korea</Text0>
        <Text1>Made with Framer X</Text1>
        <System.Spacing type="v" width={isMobile ? 16 : 24} />
        <System.Divider showOnMobile />
        <System.Spacing type="v" width={isMobile ? 16 : 24} />
        <Link0
          href="https://www.facebook.com/groups/framerkorea/"
          target="_blank"
        >
          <Icon.FacebookLogo />
        </Link0>
        <System.Spacing type="h" width={20} />
        <Link1 href="http://framer.com" target="_blank">
          <Icon.FramerLogo fill={selectedTheme.color.secondaryText} />
        </Link1>
      </Content>
    </Wrap>
  )
}

Footer.defaultProps = {
  theme: 'light',
}

import * as React from 'react'
import { useContext, useState } from 'react'
import styled, { css, ThemeContext } from 'styled-components'
import { motion } from 'framer-motion'
import { Text } from '../Text'
import { Spacing } from '../Spacing'
import { themes } from '../../../base'

export function CardLg({
  title,
  speaker,
  jobtitle,
  workplace,
  theme,
  bg,
  onClick,
  ...props
}) {
  const selectedTheme = useContext(ThemeContext) || themes[theme]
  const [hover, setHover] = useState(false)

  const Wrap = styled.div`
    height: 360px;
    border-radius: 12px;
    cursor: pointer;
    outline: none;
    border: none;
    background-image: url(${bg});
    background-size: cover;
    box-shadow: ${selectedTheme.color.boxShadow};
    position: relative;
    @media (max-width: 1080px) {
      height: 316px;
    }
  `

  const Talks = styled.div`
    margin-right: 24px;
    margin-left: 24px;
    position: absolute;
    display: grid;
    grid-gap: 12px;
    bottom: 16px;
    @media (max-width: 414px) {
      margin-right: 16px;
      margin-left: 16px;
      bottom: 12px;
      grid-gap: 10px;
    }
  `

  const hoverVariants = {
    active: {
      y: -12,
      boxShadow: selectedTheme.color.boxShadowHover,
      transition: 0.3,
    },
    inactive: {
      y: 0,
      boxShadow: selectedTheme.color.boxShadow,
      transition: 0.3,
    },
  }
  return (
    <motion.div
      style={{
        borderRadius: 12,
        background: bg,
        border: selectedTheme.color.border,
      }}
      variants={hoverVariants}
      onHoverStart={() => setHover(true)}
      onHoverEnd={() => setHover(false)}
      onClick={() => {
        onClick()
        setHover(false)
      }}
      animate={hover ? 'active' : 'inactive'}
    >
      <Wrap>
        <Talks>
          <Text
            type="Heading-3"
            text={title}
            align="left"
            style={{ color: '#FFFFFF' }}
          />
          <div style={{ color: '#FFFFFF' }}>
            <Text
              type="Heading-4"
              text={speaker}
              align="left"
              style={{ color: '#FFFFFF', display: 'inline-block' }}
            />{' '}
            /{' '}
            <Text
              type="Heading-4"
              text={jobtitle.length > 1 ? jobtitle : workplace}
              align="left"
              style={{ color: '#FFFFFF', display: 'inline-block' }}
            />
          </div>
        </Talks>
      </Wrap>
    </motion.div>
  )
}

CardLg.defaultProps = {
  theme: 'light',
  title: 'title',
  speaker: 'speaker',
  jobtitle: 'jobtitle',
  workplace: 'workplace',
}

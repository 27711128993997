import * as React from 'react'
import { useContext } from 'react'
import styled, { css, ThemeContext } from 'styled-components'
import { useIntl, Link, FormattedMessage } from 'gatsby-plugin-intl'
import { motion } from 'framer-motion'
import { themes } from '../../../base'
import { workshopFileUrl } from '../../../base/consts'
import { CardXL, CardSm } from '../Card'
import { Spacing } from '../Spacing'
import { Text } from '../Text'
import * as System from '../..'

const ContentWrap = styled.div`
  display: grid;
  margin-left: 60px;
  margin-right: 60px;
  grid-template-columns: 5fr 3fr;
  @media (max-width: 1080px) {
    grid-template-columns: 1fr;
    margin-left: 22px;
    margin-right: 22px;
  }
`

const TitleWrap = styled.div`
  position: relative;
`

const SwitchWrap = styled.div`
  width: 296px;
  height: 202px;
  float: right;
  @media (max-width: 1080px) {
    margin-left: auto;
    margin-right: auto;
    float: none;
  }
`
const DownloadButton = styled(motion.a)`
  width: 48px;
  height: 48px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`

export function Workshop({ currentStep, theme, title, body }) {
  const selectedTheme = useContext(ThemeContext) || themes[theme]
  return (
    <CardXL>
      <ContentWrap>
        <div>
          <Spacing height="50" mobileHeight="22" />
          <TitleWrap>
            <Text type="Heading-2" text={title} />
            <DownloadButton>
              <System.DownloadButton fileUrl={workshopFileUrl} type="noBg" />
            </DownloadButton>
          </TitleWrap>

          <Spacing height="32" mobileHeight="none" />
          <div>
            <System.Divider />
            <Spacing height="30" mobileHeight="0" />
            <Text type="Body" text={body} />
            <Spacing height="51" mobileHeight="40" />
          </div>
        </div>
        <div
          style={{
            padding: 0,
            marginTop: 'auto',
            marginBottom: 'auto',
          }}
        >
          <SwitchWrap>
            <System.WorkshopSwitch currentStep={currentStep} />
          </SwitchWrap>
          <Spacing height="0" mobileHeight="22" />
        </div>
      </ContentWrap>
    </CardXL>
  )
}

Workshop.defaultProps = {
  theme: 'light',
  title: 'title',
  body: 'body',
}

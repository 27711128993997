import * as React from 'react'
import { useContext } from 'react'
import styled, { css, ThemeContext } from 'styled-components'
import { themes } from '../../../base'
import * as System from '../../../design-system'
import * as Icon from '../../../design-system/components/Icons'

const Wrap = styled.div`
  width: 48px;
  height: 48px;
  cursor: pointer;
  position: relative;
`
const Center = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export function GotoTopButton({ ...props }) {
  return (
    <Wrap>
      <Center
        style={{
          marginTop: 10,
        }}
      >
        <BG />
      </Center>
      <Center
        style={{
          width: 22,
          height: 22,
        }}
      >
        <Icon.ArrowTop />
      </Center>
    </Wrap>
  )
}

function BG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="76"
      height="76"
    >
      <g>
        <defs>
          <path
            d="M 38 54 C 51.255 54 62 43.255 62 30 C 62 16.745 51.255 6 38 6 C 24.745 6 14 16.745 14 30 C 14 43.255 24.745 54 38 54 Z"
            id="a1257z"
          />
          <filter
            id="a1259z"
            x="-61.1%"
            y="-77.8%"
            width="222.0%"
            height="255.3%"
            filterUnits="objectBoundingBox"
          >
            <feOffset dx="0" dy="8" in="SourceAlpha" result="a1261z" />
            <feGaussianBlur stdDeviation="7" in="a1261z" result="a1262z" />
            <feColorMatrix
              colorInterpolationFilters="sRGB"
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.15 0"
              type="matrix"
              in="a1262z"
              result="a1263z"
            />
          </filter>
          <linearGradient
            id="idwGAzJRQRBg1131693984"
            gradientTransform="rotate(90, 0.5, 0.5)"
          >
            <stop offset="0" stopColor="rgba(178,1,254,1)" stopOpacity="1" />
            <stop offset="1" stopColor="rgba(150,45,255,1)" stopOpacity="1" />
          </linearGradient>
        </defs>
        <g filter="url(#a1259z)">
          <use
            fill="black"
            fillOpacity="1"
            stroke="black"
            strokeOpacity="0"
            strokeWidth="0"
            xlinkHref="#a1257z"
            clipPath="url(#a1258z)"
          />
        </g>
        <use
          xlinkHref="#a1257z"
          fill="url(#idwGAzJRQRBg1131693984)"
          clipPath="url(#a1258z)"
        />
      </g>
    </svg>
  )
}

GotoTopButton.defaultProps = {}

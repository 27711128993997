import * as React from 'react'
import { useState, useContext } from 'react'
import { motion } from 'framer-motion'
import styled, { ThemeContext, css } from 'styled-components'
import { sleep } from '../../../base/utils'
import * as System from '../../../design-system'
import { themes } from '../../../base'

const Wrap = styled.div`
  width: 110px;
  height: 29px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  vertical-align: middle;
  ${props =>
    props.selectedTheme &&
    css`
      background: ${props.selectedTheme.color.segmentControlBg};
    `}
`
const RowWrap = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`
const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-columns: center;
  height: 100%;
`

const Column = styled.div`
  background: transparent;
  flex: 0 0 50%;
  p {
    line-height: 29px;
    text-align: center;
  }
`
const Block = styled(motion.div)`
  background: #fff;
  width: 52px;
  height: 25px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 8px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);
  ${props =>
    props.selectedTheme &&
    css`
      background: ${props.selectedTheme.color.segmentControlBlock};
    `}
`

export function SegmentedControl({
  theme,
  currentLocale,
  changeLocale,
  ...rest
}) {
  const [locale, setLocale] = useState(currentLocale)
  const selectedTheme = useContext(ThemeContext) || themes[theme]

  return (
    <Wrap
      onClick={async () => {
        setLocale(locale === 'en' ? 'ko' : 'en')
        await sleep(0.5) // wait until animation finished
        changeLocale(currentLocale === 'en' ? 'ko' : 'en')
      }}
      selectedTheme={selectedTheme}
    >
      <Block
        initial={{
          left: locale === 'ko' ? 2 : 56, // prevent ticking when parent state(currentStep) updates
        }}
        animate={{
          left: locale === 'ko' ? 2 : 56,
        }}
        transition={{
          type: 'spring',
          damping: 20,
          mass: 0.3,
        }}
        selectedTheme={selectedTheme}
      />
      <RowWrap>
        <Row>
          <Column>
            <System.Text
              text="KO"
              type="Heading-4"
              align="center"
              display="block"
              style={{ color: selectedTheme.color.secondaryText }}
            />
          </Column>
          <Column>
            <System.Text
              text="EN"
              align="center"
              display="block"
              type="Heading-4"
              style={{ color: selectedTheme.color.secondaryText }}
            />
          </Column>
        </Row>
      </RowWrap>
    </Wrap>
  )
}

SegmentedControl.defaultProps = {
  width: 110,
  height: 29,
  theme: 'light',
}

import * as React from 'react'
import styled, { css, ThemeContext } from 'styled-components'
import { useContext, useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { themes } from '../../../base'
import { sleep } from '../../../base/utils'

const Wrap = styled.div`
  background: ${themes.dark.color.themeToggleBg};
  width: 40px;
  height: 24px;
  border-radius: 100px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  vertical-align: middle;
`
const LightBg = styled(motion.div)`
  background: ${themes.light.color.themeToggleBg};
  width: 100%;
  height: 100%;
  border-radius: 100px;
`
const Pin = styled(motion.div)`
  width: 16px;
  height: 16px;
  border-radius: 100px;
  position: absolute;
  top: 4px;
  left: 4px;
  background: white;
`

export function ThemeToggle({ onClick, theme, ...rest }) {
  const selectedTheme = useContext(ThemeContext) || themes[theme] // it make ignoring revert animation transition
  const [th, setTheme] = useState(selectedTheme.key)

  return (
    <Wrap
      onClick={async () => {
        setTheme(th === 'light' ? 'dark' : 'light')
        await sleep(0.36) // wait until animation finished
        onClick() // change the real theme state at here
      }}
    >
      <LightBg
        initial={{
          scale: th === 'light' ? 1 : 0, // prevent ticking when parent state(currentStep) updates
        }}
        animate={{
          scale: th === 'light' ? 1 : 0,
        }}
        transition={{ type: 'tween' }}
      />
      <Pin
        initial={{
          left: th === 'light' ? 4 : 20, // prevent ticking when parent state(currentStep) updates
        }}
        animate={{
          left: th === 'light' ? 4 : 20,
        }}
        transition={{ duration: 0.3 }}
        style={{
          background: selectedTheme.color.themeTogglePin,
        }}
      />
    </Wrap>
  )
}

ThemeToggle.defaultProps = {
  theme: 'light',
}

import * as React from 'react'

export function TitleDark() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 530 250">
      <g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1">
        <path
          fill="#FFF"
          d="M35.96 80V59.52h42.96v-15.6H35.96V39.6H79.8V24h-76v56h32.16zm75.4 0V65.6h17.68l6.72 14.4h28.88l-7.68-16.56c.96-.64 1.92-1.52 3.28-2.64 4.32-3.6 5.36-10.64 5.36-16 0-5.84-1.2-12.24-5.84-16.32C154.8 24.8 146.56 24 141.6 24H84v56h27.36zm16.822-32.24H111.36V42.4h16.4c1.84 0 4.64-.16 6.4.4 1.6.48 2.08 1.44 2.08 2.16 0 .72-.56 1.92-2.08 2.32-1.709.446-4.107.478-5.978.48zM198.6 80l3.36-7.04h29.68L235 80h29.6l-26.56-56h-42.48L169 80h29.6zm25.68-22.64h-14.96l7.52-15.76 7.44 15.76zM295.68 80V59.12L308.24 80h10.8l12.48-20.72V80h27.68V24h-27.68v.16L313.68 54l-18-30H268v56h27.68zm143.72 0V64.4h-43.84v-4.88h42.96v-15.6h-42.96V39.6h43.84V24h-76v56h76zm31.56 0V65.6h17.68l6.72 14.4h28.88l-7.68-16.56c.96-.64 1.92-1.52 3.28-2.64 4.32-3.6 5.36-10.64 5.36-16 0-5.84-1.2-12.24-5.84-16.32C514.4 24.8 506.16 24 501.2 24h-57.6v56h27.36zm16.822-32.24H470.96V42.4h16.4c1.84 0 4.64-.16 6.4.4 1.6.48 2.08 1.44 2.08 2.16 0 .72-.56 1.92-2.08 2.32-1.709.446-4.107.478-5.978.48zM34.84 234v-20.88L47.4 234h10.8l12.48-20.72V234h27.68v-56H70.68v.16L52.84 208l-18-30H7.16v56h27.68zm143.72 0v-15.6h-43.84v-4.88h42.96v-15.6h-42.96v-4.32h43.84V178h-76v56h76zm80.2 0v-15.6h-43.84v-4.88h42.96v-15.6h-42.96v-4.32h43.84V178h-76v56h76zm59.72 0v-34.4h23.68V178h-80v21.6h24.32V234h32zm71.32 1.36c16.4 0 27.84-5.12 32.8-8.64 4.96-3.52 9.84-11.04 9.84-25.84L432.2 178h-28.96l.08 22.96c0 5.92-3.2 12.48-14.64 12.48-11.44 0-14.24-6.72-14.24-12.56l-.16-22.88h-28.72v22.96c0 18.8 6.08 22.72 10.96 26.64 4.88 3.92 15.84 7.76 33.28 7.76zm75.64-1.36v-14.4h30.96c8.88 0 14-.64 19.12-4.8 4.4-3.6 5.52-10.64 5.52-16 0-5.84-1.28-12.24-6-16.32-5.12-3.68-13.52-4.48-18.64-4.48h-58.96v56h28zm17.272-32.24H465.44v-5.36h16.8c1.84 0 4.72-.16 6.56.4 1.6.48 2.08 1.44 2.08 2.16 0 .72-.56 1.92-2.08 2.32-1.772.443-4.158.477-6.088.48z"
          transform="translate(-695 -190) translate(695 190)"
        />
        <path
          fill="#333"
          fillOpacity="0"
          stroke="#FFF"
          strokeWidth="2"
          d="M83.56 157v-9.6l5.2-5.2 11.28 14.8h33.2l-26.88-32.08L130.6 101H94.28l-10.72 10.8V101H52.2v56h31.36zm115.16-5.04c8.08-5.12 12.08-15.2 12.08-22.56 0-7.36-3.92-17.52-12.08-22.56-9.04-5.6-21.04-6.64-30.72-6.64-9.6 0-22.4 1.04-31.44 6.64-8.24 5.12-11.92 15.12-11.92 22.56 0 7.36 3.84 17.36 11.92 22.56 8.881 5.738 22.55 6.224 30.917 6.24h.443c8.32 0 21.76-.48 30.8-6.24zm-30.626-11.36h-.334c-3.2 0-8.56-.16-12.08-2.4-3.12-2-4.56-5.84-4.56-8.64 0-2.88 1.44-6.8 4.56-8.72 3.52-2.16 8.96-2.24 12.16-2.24 3.2 0 8.4 0 11.84 2.24 3.04 2 4.64 5.84 4.64 8.72 0 2.8-1.6 6.64-4.64 8.64-3.403 2.243-8.3 2.392-11.586 2.4zM243.16 157v-14.4h17.68l6.72 14.4h28.88l-7.68-16.56c.96-.64 1.92-1.52 3.28-2.64 4.32-3.6 5.36-10.64 5.36-16 0-5.84-1.2-12.24-5.84-16.32-4.96-3.68-13.2-4.48-18.16-4.48h-57.6v56h27.36zm16.822-32.24H243.16v-5.36h16.4c1.84 0 4.64-.16 6.4.4 1.6.48 2.08 1.44 2.08 2.16 0 .72-.56 1.92-2.08 2.32-1.709.446-4.107.478-5.978.48zM377.6 157v-15.6h-43.84v-4.88h42.96v-15.6h-42.96v-4.32h43.84V101h-76v56h76zm33 0l3.36-7.04h29.68L447 157h29.6l-26.56-56h-42.48L381 157h29.6zm25.68-22.64h-14.96l7.52-15.76 7.44 15.76z"
          transform="translate(-695 -190) translate(695 190)"
        />
      </g>
    </svg>
  )
}

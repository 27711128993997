import * as React from 'react'
import styled, { css, ThemeContext } from 'styled-components'
import { useContext } from 'react'
import { themes } from '../../../base'
import FramerLogoDark from '../../../src/images/01-d.svg'
import FramerLogoLight from '../../../src/images/01-w.svg'
import SpectrumLogoDark from '../../../src/images/02-d.svg'
import SpectrumLogoLight from '../../../src/images/02-w.svg'
import HarborLogoDark from '../../../src/images/03-d.svg'
import HarborLogoLight from '../../../src/images/03-w.svg'

const SponsorWrap = styled.div`
  display: grid;
  grid-gap: 80px;
  grid-template-columns: 42px 272px 191px;
  align-items: center;
  width: 100%;
  overflow: hidden;
  @media (max-width: 720px) {
    grid-gap: 20px;
    grid-template-columns: 21px 160px 83px;
  }
`
const Link = styled.a``

export function Sponsor({ theme }) {
  const selectedTheme = useContext(ThemeContext) || themes[theme]
  return (
    <SponsorWrap>
      <Link target="_blank" href="https://www.framer.com">
        <img
          src={selectedTheme.key == 'dark' ? FramerLogoDark : FramerLogoLight}
          alt="Framer Logo"
          style={{ width: '100%', maxWidth: 400, height: 'auto' }}
        />
      </Link>

      <Link target="_blank" href="https://www.designspectrum.org">
        <img
          src={
            selectedTheme.key == 'dark' ? SpectrumLogoDark : SpectrumLogoLight
          }
          alt="Framer Logo"
          style={{ width: '100%', maxWidth: 400, height: 'auto' }}
        />
      </Link>
      <Link target="_blank" href="https://harbor.school">
        <img
          src={selectedTheme.key == 'dark' ? HarborLogoDark : HarborLogoLight}
          alt="Harbor School Logo"
          style={{ width: '100%', maxWidth: 400, height: 'auto' }}
        />
      </Link>
    </SponsorWrap>
  )
}

Sponsor.defaultProps = {
  theme: 'light',
}

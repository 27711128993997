import * as React from 'react'

export function FacebookLogo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30">
      <g>
        <path d="M 0 0 L 30 0 L 30 30 L 0 30 Z" fill="transparent" />
        <g transform="translate(1 1)">
          <path
            d="M 27.464 13.732 C 27.464 6.149 21.315 0 13.732 0 C 6.149 0 0 6.149 0 13.732 C 0 20.585 5.02 26.266 11.586 27.297 L 11.586 17.702 L 8.1 17.702 L 8.1 13.732 L 11.587 13.732 L 11.587 10.706 C 11.587 7.265 13.638 5.363 16.774 5.363 C 18.277 5.363 19.848 5.631 19.848 5.631 L 19.848 9.011 L 18.116 9.011 C 16.411 9.011 15.878 10.069 15.878 11.156 L 15.878 13.732 L 19.686 13.732 L 19.078 17.702 L 15.878 17.702 L 15.878 27.297 C 22.444 26.268 27.465 20.587 27.465 13.732 Z"
            fill="rgb(95,99,104)"
          />
          <path
            d="M 19.077 17.702 L 19.685 13.732 L 15.877 13.732 L 15.877 11.156 C 15.877 10.07 16.408 9.01 18.115 9.01 L 19.847 9.01 L 19.847 5.631 C 19.847 5.631 18.276 5.363 16.773 5.363 C 13.637 5.363 11.586 7.263 11.586 10.706 L 11.586 13.732 L 8.1 13.732 L 8.1 17.702 L 11.587 17.702 L 11.587 27.297 C 13.009 27.521 14.456 27.521 15.878 27.297 L 15.878 17.702 L 19.078 17.702 Z"
            fill="rgb(254,254,254)"
          />
        </g>
      </g>
    </svg>
  )
}

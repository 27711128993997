import * as React from 'react'

export function FramerLogo({ fill }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="24">
      <path
        d="M 0.5 1 L 15.5 1 L 15.5 8.451 L 8 8.451 Z M 0.5 8.451 L 8 8.451 L 15.5 15.902 L 8 15.902 L 8 23.353 L 0.5 15.902 Z"
        fill={fill}
      />
    </svg>
  )
}

FramerLogo.defaultProps = {
  fill: 'rgba(51,51,51,1)',
}

import * as React from 'react'
import { useContext } from 'react'
import styled, { css, ThemeContext } from 'styled-components'
import {
  motion,
  useViewportScroll,
  transform,
  useTransform,
} from 'framer-motion'
import { themes } from '../../../base'
import { isMobile, wem } from '../../../base/utils'
import { checkoutUrl } from '../../../base/consts'
import * as System from '../../../design-system'
import * as Icon from '../Icons'

const Wrap = styled(motion.div)`
  width: 100%;
  height: 740px;
  position: relative;
  ${props =>
    props.selectedTheme &&
    css`
      background: ${props.selectedTheme.color.topBackground};
      @media (max-width: ${props.selectedTheme.breakpoint.mobile}px) {
        height: 660px;
      }
    `}
`
const ShapeArea = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
`
const Shape1 = styled(motion.div)`
  width: ${wem(450)};
  height: ${wem(450)};
  overflow: visible;
  border-radius: 50%;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  position: absolute;
  ${props =>
    props.background &&
    css`
      background-color: ${props.background};
    `}
  ${props =>
    props.selectedTheme &&
    css`
      @media (max-width: ${props.selectedTheme.breakpoint.mobile}px) {
        width: 180px;
        height: 180px;
      }
    `}
`
const Shape2 = styled(motion.div)`
  position: absolute;
  left: ${wem(169)};
  width: ${wem(480)};
  height: ${wem(480)};
  ${props =>
    props.selectedTheme &&
    css`
      @media (max-width: ${props.selectedTheme.breakpoint.mobile}px) {
        width: 184px;
        height: 170px;
        left: -12px;
      }
    `}
`
const Shape3 = styled(motion.div)`
  width: ${wem(350)};
  height: ${wem(350)};
  overflow: visible;
  border-radius: ${wem(120)};
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  position: absolute;
  right: ${wem(46)};
  ${props =>
    props.background &&
    css`
      background-color: ${props.background};
    `}
  ${props =>
    props.selectedTheme &&
    css`
      @media (max-width: ${props.selectedTheme.breakpoint.mobile}px) {
        width: 110px;
        height: 110px;
        border-radius: 40px;
        right: 24px;
      }
    `}
`
const Shape4 = styled(motion.div)`
  width: ${wem(500)};
  height: ${wem(500)};
  overflow: visible;
  border-radius: 50%;
  position: absolute;
  top: ${wem(-150)};
  right: ${wem(-130)};
  ${props =>
    props.background &&
    css`
      background-color: ${props.background};
    `}
  ${props =>
    props.selectedTheme &&
    css`
      @media (max-width: ${props.selectedTheme.breakpoint.mobile}px) {
        width: 180px;
        height: 180px;
        top: 0px;
        right: -50px;
      }
    `}
`
const Shape5 = styled(motion.div)`
  width: ${wem(250)};
  height: ${wem(250)};
  overflow: visible;
  border-radius: 50%;
  position: absolute;
  border-top-color: 660px;
  right: ${wem(450)};
  ${props =>
    props.background &&
    css`
      background-color: ${props.background};
    `}
  ${props =>
    props.selectedTheme &&
    css`
      @media (max-width: ${props.selectedTheme.breakpoint.mobile}px) {
        width: 100px;
        height: 100px;
        right: 20px;
      }
    `}
`
const Shape6 = styled(motion.div)`
  width: 30px;
  height: 30px;
  background-color: #760fd7;
  border-radius: 50%;
  left: 50px;
  position: absolute;
`
const Typo = styled.div`
  position: absolute;
  top: 104px;
  left: 80px;
  ${props =>
    props.selectedTheme &&
    css`
      @media (max-width: ${props.selectedTheme.breakpoint.mobile}px) {
        display: none;
      }
    `}
`

const Title = styled.div`
  max-width: 1080px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  ${props =>
    props.selectedTheme &&
    css`
      @media (max-width: ${props.selectedTheme.breakpoint.mobile}px) {
        top: 58%;
      }
    `}
`
const TitleWrap = styled(motion.div)`
  width: 530px;
  margin: 0 auto;
  ${props =>
    props.selectedTheme &&
    css`
      @media (max-width: ${props.selectedTheme.breakpoint.mobile}px) {
        width: 250px;
      }
    `}
`
const DetailWrap = styled(motion.div)``
const DetailIcon = styled.span`
  line-height: 24px;
  vertical-align: middle;
  width: 15px;
  height: 20px;
  display: inline-block;
`
const DetailText = styled.span`
  margin-left: 6px;
  line-height: 24px;
  vertical-align: middle;
  p {
    display: inline-block;
    white-space: nowrap;
  }
`
const RotateWrap = styled.div`
  width: 150px;
  height: 150px;
  position: absolute;
  top: 0;
  right: 82px;
  ${props =>
    props.selectedTheme &&
    css`
      @media (max-width: ${props.selectedTheme.breakpoint.mobile}px) {
        right: 50%;
        transform: translateX(50%);
      }
    `}
`
const FramerLogo = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 32px;
  height: 47px;
`
const Rotate = styled(motion.div)``

const designedScrollMax = isMobile ? -5113 : -3803

export function Top({ text, theme, desc1, desc2, registerText, ...props }) {
  const selectedTheme = useContext(ThemeContext) || themes[theme]
  console.log('hi')

  const { scrollYProgress } = useViewportScroll()
  const shape1Top = useTransform(scrollYProgress, value =>
    isMobile
      ? transform(value * designedScrollMax, [0, -500], [100, 0])
      : transform(value * designedScrollMax, [0, -680], [240, 140])
  )
  const shape2Top = useTransform(scrollYProgress, value =>
    isMobile
      ? transform(value * designedScrollMax, [0, -500], [22, 80])
      : transform(value * designedScrollMax, [0, -680], [70, 150])
  )
  const shape2Rotate = useTransform(scrollYProgress, value =>
    isMobile
      ? transform(value * designedScrollMax, [0, -400], [10, 35])
      : transform(value * designedScrollMax, [0, -400], [0, 30])
  )

  const shape3Top = useTransform(scrollYProgress, value =>
    isMobile
      ? transform(value * designedScrollMax, [0, -500], [130, 190])
      : transform(value * designedScrollMax, [0, -680], [213, 300])
  )
  const shape3Rotate = useTransform(scrollYProgress, value =>
    transform(value * designedScrollMax, [0, -300], [-30, -60])
  )
  const shape5Top = useTransform(scrollYProgress, value =>
    isMobile
      ? transform(value * designedScrollMax, [0, -500], [620, 580])
      : transform(value * designedScrollMax, [0, -680], [660, 560])
  )
  // last small purple circle (Shape6)
  const shape6Top = useTransform(scrollYProgress, value =>
    isMobile
      ? transform(value * designedScrollMax, [0, -500], [660, 530])
      : transform(value * designedScrollMax, [0, -500], [660, 530])
  )

  return (
    <Wrap
      selectedTheme={selectedTheme}
      animate={{
        background: selectedTheme.color.topBackground,
      }}
      id="top"
    >
      <ShapeArea>
        {isMobile && (
          <Shape6
            style={{
              y: shape6Top,
            }}
          />
        )}
        <Shape5
          style={{
            y: shape5Top,
          }}
          selectedTheme={selectedTheme}
          background={
            selectedTheme.key === 'light' ? '#ff841d' : 'rgb(68, 216, 134)'
          }
        />
        <Shape4
          selectedTheme={selectedTheme}
          background={
            selectedTheme.key === 'light' ? '#083c51' : 'rgb(96, 16, 234)'
          }
        />

        <Shape3
          style={{
            y: shape3Top,
            rotateZ: shape3Rotate,
          }}
          selectedTheme={selectedTheme}
          background={
            selectedTheme.key === 'light'
              ? 'rgba(68, 216, 134, 0.7)'
              : 'rgba(165, 16, 238, 0.7)'
          }
        />
        <Shape2
          style={{
            y: shape2Top,
            rotateZ: shape2Rotate,
          }}
          selectedTheme={selectedTheme}
        >
          <Icon.Shape2
            fill={
              selectedTheme.key === 'light'
                ? 'rgb(143, 9, 246)'
                : 'rgb(255, 151, 41)'
            }
          />
        </Shape2>
        <Shape1
          style={{
            y: shape1Top,
            x: -90,
          }}
          selectedTheme={selectedTheme}
          background={
            selectedTheme.key === 'light'
              ? 'rgba(7, 189, 248, 0.7)'
              : 'rgba(255, 100, 0, 0.7)'
          }
        />
      </ShapeArea>
      <Typo selectedTheme={selectedTheme}>
        <Icon.Typo
          fill={
            selectedTheme.key === 'light' ? 'rgb(34,34,34)' : 'rgb(255,255,255)'
          }
        />
      </Typo>
      <Title selectedTheme={selectedTheme}>
        <RotateWrap selectedTheme={selectedTheme}>
          <FramerLogo>
            <Icon.FramerLogoGrad
              rotate={
                selectedTheme.key === 'light'
                  ? 'rotate(-45.00000000000001, 0.5, 0.5)'
                  : 'rotate(90, 0.5, 0.5)'
              }
              fillFrom={
                selectedTheme.key === 'light'
                  ? 'rgba(74,12,219,1)'
                  : 'rgba(215,123,255,1)'
              }
              fillTo={
                selectedTheme.key === 'light'
                  ? 'rgba(131,2,255,1)'
                  : 'rgba(150,45,255,1)'
              }
            />
          </FramerLogo>
          <Rotate
            animate={{
              rotate: 360,
            }}
            transition={{ ease: 'linear', duration: 4, loop: Infinity }}
          >
            <Icon.Rotate
              fill={
                selectedTheme.key === 'light'
                  ? 'rgb(98,8,234)'
                  : 'rgb(232,118,253)'
              }
            />
          </Rotate>
        </RotateWrap>
        <System.Spacing height={isMobile ? 171 : 90} />
        <TitleWrap
          selectedTheme={selectedTheme}
          animate={{
            opacity: selectedTheme.key === 'light' ? 1 : 0,
          }}
          style={{
            display: selectedTheme.key === 'light' ? 'inherit' : 'none',
          }}
        >
          <Icon.TitleWhite />
        </TitleWrap>
        <TitleWrap
          selectedTheme={selectedTheme}
          animate={{
            opacity: selectedTheme.key === 'dark' ? 1 : 0,
          }}
          style={{
            display: selectedTheme.key === 'dark' ? 'inherit' : 'none',
          }}
        >
          <Icon.TitleDark />
        </TitleWrap>
        <System.Spacing height={30} />
        <DetailWrap>
          <DetailIcon>
            <Icon.Location fill={selectedTheme.color.secondaryText} />
          </DetailIcon>
          <DetailText>
            <System.Text text={desc1} type="Title-Detail" />
          </DetailText>
          {isMobile ? (
            <System.Spacing height={10} type="v" />
          ) : (
            <System.Spacing width={35} type="h" />
          )}
          <DetailIcon>
            <Icon.Time fill={selectedTheme.color.secondaryText} />
          </DetailIcon>
          <DetailText>
            <System.Text text={desc2} type="Title-Detail" />
          </DetailText>
        </DetailWrap>
        <System.Spacing height={isMobile ? 40 : 72} />
        <a href={checkoutUrl} target="_blank">
          <System.Button text={registerText} />
        </a>
      </Title>
    </Wrap>
  )
}

Top.defaultProps = {
  theme: 'light',
  desc1: 'desc1',
  desc2: 'desc2',
  registerText: 'registerText',
}

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { changeLocale, useIntl, Link } from 'gatsby-plugin-intl'

import './layout.css'
import * as System from '../../design-system'
import useTheme from '../store/hooks/useTheme'
import usePopup from '../store/hooks/usePopup'

const Layout = ({ children }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)
  const intl = useIntl()
  const [theme, setTheme] = useTheme()
  const [popup, setPopup] = usePopup()

  return (
    <System.Theme theme={theme}>
      <System.Header
        // siteTitle={data.site.siteMetadata.title}
        siteTitle={intl.formatMessage({ id: 'home.header.fmk' })}
        setTheme={setTheme}
        Link={Link}
        currentLocale={intl.locale}
        changeLocale={changeLocale}
      />
      <main>{children}</main>
      <System.Footer />
      {popup.isOpened && (
        <System.Popup
          onDimShadowClick={() =>
            setPopup({
              isOpened: false,
            })
          }
          data={popup.data}
        />
      )}
    </System.Theme>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

import * as React from 'react'
import { useContext } from 'react'
import styled, { css, ThemeContext } from 'styled-components'
import { themes } from '../../../base'
import * as Icon from '../../../design-system/components/Icons'
import * as System from '../../../design-system'

const Bin = ({ children, to }) => <a href={to}>{children}</a>

export function Header({
  theme,
  Link,
  siteTitle,
  setTheme,
  currentLocale,
  changeLocale,
  ...props
}) {
  const selectedTheme = useContext(ThemeContext) || themes[theme]
  const A = Link || Bin

  const Wrap = styled.header`
    height: 60px;
    background: ${selectedTheme.color.primaryBg};
    box-shadow: ${selectedTheme.color.headerShadow};
    position: fixed;
    width: 100%;
    z-index: 1;
  `
  const ContentArea = styled.div`
    width: 1080px;
    margin: 0 auto;
    position: relative;
    @media (max-width: ${selectedTheme.breakpoint.mobile}px) {
      width: 100vw;
      margin: initial;
    }
  `
  const FMK = styled.div`
    white-space: nowrap;
    svg {
      vertical-align: middle;
    }
    p {
      display: inline-block;
      line-height: 60px;
      vertical-align: middle;
      margin-left: 24px;
    }
    @media (max-width: ${selectedTheme.breakpoint.mobile}px) {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  `
  const Actions = styled.div`
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    > p,
    > div {
      display: inline-block;
    }
    @media (max-width: ${selectedTheme.breakpoint.mobile}px) {
      display: none;
    }
  `

  return (
    <Wrap>
      <ContentArea>
        <FMK>
          <A to="/">
            <Icon.FramerLogo fill={selectedTheme.color.headerText} />
            <System.Text text={siteTitle} type="Header-Text" />
          </A>
        </FMK>

        <Actions>
          <System.Text
            text="Dark Mode"
            style={{
              color: '#5f6368',
              fontSize: 16,
              fontWeight: 700,
              lineHeight: '24px',
              verticalAlign: 'middle',
            }}
          />
          <System.Spacing type="h" width={8} />
          <System.ThemeToggle onClick={setTheme} />
          <System.Spacing type="h" width={40} />
          <System.SegmentedControl
            currentLocale={currentLocale}
            changeLocale={changeLocale}
          />
        </Actions>
      </ContentArea>
    </Wrap>
  )
}

Header.defaultProps = {
  theme: 'light',
  siteTitle: 'fmk',
}

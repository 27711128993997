import * as React from 'react'

export function TimeBoxA() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="288"
      height="163"
    >
      <g>
        <defs>
          <path
            d="M 14 33 C 14 26.373 19.373 21 26 21 L 133 21 C 133 21 138.981 11.433 140.991 8.329 C 143 5.224 144.996 5.224 147.005 8.329 C 149.013 11.433 155 21 155 21 L 262 21 C 268.627 21 274 26.373 274 33 L 274 129 C 274 135.627 268.627 141 262 141 L 26 141 C 19.373 141 14 135.627 14 129 Z"
            id="a1199z"
          />
          <filter
            id="a1201z"
            x="-11.3%"
            y="-27.7%"
            width="122.6%"
            height="155.3%"
            filterUnits="objectBoundingBox"
          >
            <feOffset dx="0" dy="8" in="SourceAlpha" result="a1203z" />
            <feGaussianBlur stdDeviation="7" in="a1203z" result="a1204z" />
            <feColorMatrix
              colorInterpolationFilters="sRGB"
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.15 0"
              type="matrix"
              in="a1204z"
              result="a1205z"
            />
          </filter>
          <linearGradient
            id="idpsYJYV4hBg1131693984"
            gradientTransform="rotate(90, 0.5, 0.5)"
          >
            <stop offset="0" stopColor="rgba(178,1,254,1)" stopOpacity="1" />
            <stop offset="1" stopColor="rgba(150,45,255,1)" stopOpacity="1" />
          </linearGradient>
        </defs>
        <g filter="url(#a1201z)">
          <use
            fill="black"
            fillOpacity="1"
            stroke="black"
            strokeOpacity="0"
            strokeWidth="0"
            xlinkHref="#a1199z"
            clipPath="url(#a1200z)"
          />
        </g>
        <use
          xlinkHref="#a1199z"
          fill="url(#idpsYJYV4hBg1131693984)"
          clipPath="url(#a1200z)"
        />
      </g>
    </svg>
  )
}

export function TimeBoxB() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="268"
      height="163"
    >
      <g>
        <defs>
          <path
            d="M 14 33 C 14 26.373 19.373 21 26 21 L 123 21 C 123 21 128.981 11.433 130.991 8.329 C 133 5.224 134.996 5.224 137.005 8.329 C 139.013 11.433 145 21 145 21 L 242 21 C 248.627 21 254 26.373 254 33 L 254 129 C 254 135.627 248.627 141 242 141 L 26 141 C 19.373 141 14 135.627 14 129 Z"
            id="a1192z"
          />
          <filter
            id="a1194z"
            x="-12.2%"
            y="-27.7%"
            width="124.4%"
            height="155.3%"
            filterUnits="objectBoundingBox"
          >
            <feOffset dx="0" dy="8" in="SourceAlpha" result="a1196z" />
            <feGaussianBlur stdDeviation="7" in="a1196z" result="a1197z" />
            <feColorMatrix
              colorInterpolationFilters="sRGB"
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.15 0"
              type="matrix"
              in="a1197z"
              result="a1198z"
            />
          </filter>
        </defs>
        <g filter="url(#a1194z)">
          <use
            fill="black"
            fillOpacity="1"
            stroke="black"
            strokeOpacity="0"
            strokeWidth="0"
            xlinkHref="#a1192z"
            clipPath="url(#a1193z)"
          />
        </g>
        <use
          xlinkHref="#a1192z"
          fill="rgba(95,99,104,1)"
          clipPath="url(#a1193z)"
        />
      </g>
    </svg>
  )
}

export function TimeBoxC() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="268"
      height="163"
    >
      <g>
        <defs>
          <path
            d="M 14 33 C 14 26.373 19.373 21 26 21 L 123 21 C 123 21 128.981 11.433 130.991 8.329 C 133 5.224 134.996 5.224 137.005 8.329 C 139.013 11.433 145 21 145 21 L 242 21 C 248.627 21 254 26.373 254 33 L 254 129 C 254 135.627 248.627 141 242 141 L 26 141 C 19.373 141 14 135.627 14 129 Z"
            id="a1185z"
          />
          <filter
            id="a1187z"
            x="-12.2%"
            y="-27.7%"
            width="124.4%"
            height="155.3%"
            filterUnits="objectBoundingBox"
          >
            <feOffset dx="0" dy="8" in="SourceAlpha" result="a1189z" />
            <feGaussianBlur stdDeviation="7" in="a1189z" result="a1190z" />
            <feColorMatrix
              colorInterpolationFilters="sRGB"
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.15 0"
              type="matrix"
              in="a1190z"
              result="a1191z"
            />
          </filter>
          <linearGradient
            id="idvaNoVCYYtg-282267647"
            gradientTransform="rotate(90, 0.5, 0.5)"
          >
            <stop offset="0" stopColor="rgba(132,0,255,1)" stopOpacity="1" />
            <stop offset="1" stopColor="rgba(95,0,255,1)" stopOpacity="1" />
          </linearGradient>
        </defs>

        <use
          xlinkHref="#a1185z"
          fill="url(#idvaNoVCYYtg-282267647)"
          clipPath="url(#a1186z)"
        />
      </g>
    </svg>
  )
}

export function TimeBoxD() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="320"
      height="163"
    >
      <g>
        <defs>
          <path
            d="M 14 33 C 14 26.373 19.373 21 26 21 L 149 21 C 149 21 154.981 11.433 156.991 8.329 C 159 5.224 160.996 5.224 163.005 8.329 C 165.013 11.433 171 21 171 21 L 294 21 C 300.627 21 306 26.373 306 33 L 306 129 C 306 135.627 300.627 141 294 141 L 26 141 C 19.373 141 14 135.627 14 129 Z"
            id="a1178z"
          />
          <filter
            id="a1180z"
            x="-10.1%"
            y="-27.7%"
            width="120.1%"
            height="155.3%"
            filterUnits="objectBoundingBox"
          >
            <feOffset dx="0" dy="8" in="SourceAlpha" result="a1182z" />
            <feGaussianBlur stdDeviation="7" in="a1182z" result="a1183z" />
            <feColorMatrix
              colorInterpolationFilters="sRGB"
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.15 0"
              type="matrix"
              in="a1183z"
              result="a1184z"
            />
          </filter>
          <linearGradient
            id="idJxr4OK5aag2048813023"
            gradientTransform="rotate(90, 0.5, 0.5)"
          >
            <stop offset="0" stopColor="rgba(68,1,255,1)" stopOpacity="1" />
            <stop offset="1" stopColor="rgba(52,121,255,1)" stopOpacity="1" />
          </linearGradient>
        </defs>
        <g filter="url(#a1180z)">
          <use
            fill="black"
            fillOpacity="1"
            stroke="black"
            strokeOpacity="0"
            strokeWidth="0"
            xlinkHref="#a1178z"
            clipPath="url(#a1179z)"
          />
        </g>
        <use
          xlinkHref="#a1178z"
          fill="url(#idJxr4OK5aag2048813023)"
          clipPath="url(#a1179z)"
        />
      </g>
    </svg>
  )
}

export function TimeBoxL() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="228"
      height="163"
    >
      <g>
        <defs>
          <path
            d="M 202 21 C 208.627 21 214 26.373 214 33 L 214 129 C 214 135.627 208.627 141 202 141 L 26 141 C 19.373 141 14 135.627 14 129 C 14 129 14 11.066 14 8.338 C 14 5.611 16.375 5.611 17.493 6.616 C 18.61 7.622 34 21 34 21 Z"
            id="a1206z"
          />
          <filter
            id="a1208z"
            x="-14.7%"
            y="-27.7%"
            width="129.3%"
            height="155.3%"
            filterUnits="objectBoundingBox"
          >
            <feOffset dx="0" dy="8" in="SourceAlpha" result="a1210z" />
            <feGaussianBlur stdDeviation="7" in="a1210z" result="a1211z" />
            <feColorMatrix
              colorInterpolationFilters="sRGB"
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.15 0"
              type="matrix"
              in="a1211z"
              result="a1212z"
            />
          </filter>
          <linearGradient
            id="idTLveewoz_g97932255"
            gradientTransform="rotate(90, 0.5, 0.5)"
          >
            <stop offset="0" stopColor="#E29FFF" stopOpacity="1" />
            <stop offset="1" stopColor="#CF5FFF" stopOpacity="1" />
          </linearGradient>
        </defs>
        <g filter="url(#a1208z)">
          <use
            fill="black"
            fillOpacity="1"
            stroke="black"
            strokeOpacity="0"
            strokeWidth="0"
            xlinkHref="#a1206z"
            clipPath="url(#a1207z)"
          />
        </g>
        <use
          xlinkHref="#a1206z"
          fill="url(#idTLveewoz_g97932255)"
          clipPath="url(#a1207z)"
        />
      </g>
    </svg>
  )
}

export function TimeBoxR() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="349"
      height="164"
    >
      <g>
        <defs>
          <path
            d="M 15 34 C 15 27.373 20.373 22 27 22 L 315 22 C 315 22 330.39 8.622 331.507 7.616 C 332.625 6.611 335 6.611 335 9.338 C 335 12.066 335 22 335 22 L 335 22 L 335 130 C 335 136.627 329.627 142 323 142 L 27 142 C 20.373 142 15 136.627 15 130 Z"
            id="a1171z"
          />
          <filter
            id="a1173z"
            x="-9.2%"
            y="-27.7%"
            width="118.3%"
            height="155.3%"
            filterUnits="objectBoundingBox"
          >
            <feOffset dx="0" dy="8" in="SourceAlpha" result="a1175z" />
            <feGaussianBlur stdDeviation="7" in="a1175z" result="a1176z" />
            <feColorMatrix
              colorInterpolationFilters="sRGB"
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.15 0"
              type="matrix"
              in="a1176z"
              result="a1177z"
            />
          </filter>
          <linearGradient
            id="idra7R4yspyg-573249078"
            gradientTransform="rotate(89.99999999999997, 0.5, 0.5)"
          >
            <stop offset="0" stopColor="rgba(52,121,255,1)" stopOpacity="1" />
            <stop offset="1" stopColor="rgba(7,189,248,1)" stopOpacity="1" />
          </linearGradient>
        </defs>
        <g filter="url(#a1173z)">
          <use
            fill="black"
            fillOpacity="1"
            stroke="black"
            strokeOpacity="0"
            strokeWidth="0"
            xlinkHref="#a1171z"
            clipPath="url(#a1172z)"
          />
        </g>
        <use
          xlinkHref="#a1171z"
          fill="url(#idra7R4yspyg-573249078)"
          clipPath="url(#a1172z)"
        />
      </g>
    </svg>
  )
}

import * as React from 'react'
import { useContext, useEffect } from 'react'
import styled, { css, ThemeContext } from 'styled-components'
import { motion, useAnimation } from 'framer-motion'
import { themes } from '../../../base'
import { isMobile } from '../../../base/utils'
import { defaultEase } from '../../../base/consts'
import * as System from '../../../design-system'
import * as Icon from '../../../design-system/components/Icons'
import useKeyPressed from './useKeyPressed'

const Wrap = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 1;
`

const DimShadow = styled.div`
  background: #000000;
  opacity: 0.7;
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
`
const Content = styled(motion.div)`
  max-width: 712px;
  border-radius: 12px;
  box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  overflow: hidden;
  ${props =>
    props.selectedTheme &&
    css`
      background-color: ${props.selectedTheme.color.primaryBg}};
      @media (max-width: ${props.selectedTheme.breakpoint.mobile}px) {
        max-width: calc(100% - 44px);
        width: 100%;
      }
    `}
`

const ContentWrap = styled.div`
  padding: 40px 40px 0px 40px;
  ${props =>
    props.selectedTheme &&
    css`
      @media (max-width: ${props.selectedTheme.breakpoint.mobile}px) {
        padding: 20px 20px 0px 20px;
      }
    `}
`

const Top = styled.div`
  width: 100%;
  height: 120px;
  position: relative;
  ${props =>
    props.selectedTheme &&
    css`
      @media (max-width: ${props.selectedTheme.breakpoint.mobile}px) {
        height: 60px;
      }
    `}
`
const DownloadButton = styled.div`
  position: absolute;
  top: 24px;
  right: 90px;
  width: 48px;
  height: 48px;
  ${props =>
    props.selectedTheme &&
    css`
      @media (max-width: ${props.selectedTheme.breakpoint.mobile}px) {
        top: 15px;
        right: 55px;
        width: 30px;
        height: 30px;
      }
    `}
`

const CloseButton = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  width: 48px;
  height: 48px;
  cursor: pointer;
  ${props =>
    props.selectedTheme &&
    css`
      @media (max-width: ${props.selectedTheme.breakpoint.mobile}px) {
        width: 30px;
        height: 30px;
        top: 15px;
        right: 15px;
      }
    `}
`
const Mask = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
const Title = styled.div``
const Badge = styled.div`
  width: 97px;
  height: 48px;
  line-height: 40px;
  border-radius: 28px;
  text-align: center;
  ${props =>
    props.selectedTheme &&
    css`
      border: 4px solid ${props.selectedTheme.color.framerPurple};
      @media (max-width: ${props.selectedTheme.breakpoint.mobile}px) {
        background: #fff;
        border: none;
        line-height: 34px;
        height: 34px;
        width: 70px;
        position: absolute;
        top: 13px;
        left: 15px;
      }
    `}
`
const Body = styled.div``
const Summary = styled.div`
  padding: 40px 40px 30px 40px;
  background: ${'top / cover no-repeat url(' +
    require('./popup-footer-w.svg') +
    ')'};
  ${props =>
    props.selectedTheme.key === 'dark' &&
    css`
      background: ${'top / cover no-repeat url(' +
        require('./popup-footer-d.svg') +
        ')'};
    `}
  ${props =>
    props.selectedTheme &&
    css`
      @media (max-width: ${props.selectedTheme.breakpoint.mobile}px) {
        padding: 20px 20px 18px 20px;
      }
    `}
`

export function Popup({ theme, onDimShadowClick, data, coverImage, ...props }) {
  const selectedTheme = useContext(ThemeContext) || themes[theme]
  const pressed = useKeyPressed()
  const contentAnim = useAnimation()
  const closePopup = async () => {
    // await contentAnim.start({
    //   scale: 0.7,
    //   opacity: 0,
    //   transition: defaultEase,
    // })
    onDimShadowClick()
  }
  useEffect(() => {
    if (pressed === 27) closePopup() // press esc key to hide popup
  }, [pressed])
  useEffect(() => {
    console.log('create popup')
    async function openPopup() {
      await contentAnim.start({
        scale: 0.7,
        opacity: 0,
        transition: {
          duration: 0,
        },
      })
      contentAnim.start({
        scale: 1,
        opacity: 1,
        transition: {
          ...defaultEase,
          delay: 0.1,
          opacity: {
            duration: 0.5,
          },
        },
      })
    }
    openPopup()
  }, [])

  return (
    <Wrap>
      <DimShadow onClick={closePopup} />
      <motion.div
        animate={contentAnim}
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <Content selectedTheme={selectedTheme}>
          <Top selectedTheme={selectedTheme}>
            <Mask src={coverImage} />
            {data.file && (
              <DownloadButton
                // href={data.file}
                // target="_blank"
                // initial={{
                //   y: '-50%',
                // }}
                // whileTap={{
                //   scale: 0.75,
                // }}
                selectedTheme={selectedTheme}
              >
                <System.DownloadButton fileUrl={data.file} />
                {/* <Icon.Download fill={selectedTheme.color.framerPurple} /> */}
              </DownloadButton>
            )}
            <CloseButton onClick={closePopup} selectedTheme={selectedTheme}>
              <Icon.CloseButton />
            </CloseButton>
          </Top>

          <ContentWrap selectedTheme={selectedTheme}>
            <Title>
              <Badge selectedTheme={selectedTheme}>
                <System.Text text="Talks" type="Popup-Badge" />
              </Badge>
              {!isMobile && <System.Spacing height={24} />}
              <System.Text text={data.title} type="Popup-Title" />
              <System.Spacing height={10} />

              <System.Text
                text={
                  data.speaker +
                  ' / ' +
                  data.jobtitle +
                  (data.jobtitle.length > 1 ? ', ' : '') +
                  data.workplace
                }
                type="Popup-SubTitle"
              />
            </Title>
            <System.Spacing height={44} />

            <Body>
              <System.Text text={data.desc} type="Popup-Body" />
            </Body>
            <System.Spacing height={40} />
          </ContentWrap>
          <Summary selectedTheme={selectedTheme}>
            <System.Text text="Summary" type="Popup-SummaryTitle" />
            <System.Spacing height={18} />
            <System.Text text={data.summary} type="Popup-Body" />
          </Summary>
        </Content>
      </motion.div>
    </Wrap>
  )
}

Popup.defaultProps = {
  theme: 'light',
  onDimShadowClick: () => {},
  data: {
    title: 'title',
    speaker: 'speaker',
    jobtitle: 'jobtitle',
    workplace: 'workplace',
    desc: 'desc',
    summary: 'summary',
  },
  coverImage: require('./popup-top.png'),
}

import { useState, useEffect } from 'react'

function hook() {
  const [pressed, setPressed] = useState(null)
  useEffect(() => {
    function onKeyDown(e) {
      const code = e.keyCode
      setPressed(code)
    }
    document.addEventListener('keydown', onKeyDown)
    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [])
  return pressed
}

export default hook

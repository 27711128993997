import * as React from 'react'

export function Time({ fill }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 20">
      <g>
        <path d="M 0 0 L 18 0 L 18 20 L 0 20 Z" fill="transparent" />
        <path
          d="M 9 2 C 13.418 2 17 5.582 17 10 C 17 14.418 13.418 18 9 18 C 4.582 18 1 14.418 1 10 C 1 5.582 4.582 2 9 2 Z M 10 6 L 8 6 L 8 11 L 13 11 L 13 9 L 10 9 Z"
          fill={fill}
        />
      </g>
    </svg>
  )
}

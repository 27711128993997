import * as React from 'react'
import { useContext, useState } from 'react'
import styled, { css, ThemeContext } from 'styled-components'
import { motion } from 'framer-motion'
import { Text } from '../Text'
import { themes } from '../../../base'
import * as System from '../../../design-system'

const DownloadButton = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  right: -32px;
  top: 50%;
  transform: translateY(-50%);
`

export function CardMd({
  title,
  speaker,
  file,
  theme,
  pointColor,
  custom,
  ...props
}) {
  const selectedTheme = useContext(ThemeContext) || themes[theme]

  const Wrap = styled.div`
    height: 252px;
    border-radius: 12px;

    outline: none;
    border: ${selectedTheme.color.border};
    background: ${selectedTheme.color.cardBg};
    position: relative;

    @media (max-width: 414px) {
      height: 150px;
      box-shadow: 0px 2px 16px 0px rgba(161, 161, 180, 0.15);
    }
  `

  const Title = styled.div`
    margin-right: 24px;
    margin-left: 24px;
    padding-top: 24px;
    @media (max-width: 414px) {
      margin-right: 16px;
      margin-left: 16px;
      padding-top: 16px;
    }
  `

  const Speaker = styled.div`
    margin-right: 24px;
    margin-left: 24px;
    position: absolute;
    bottom: 16px;
    @media (max-width: 414px) {
      margin-right: 16px;
      margin-left: 16px;
      bottom: 12px;
    }
  `

  return (
    <Wrap>
      <Title>
        <Text
          type="Heading-3"
          text={title}
          align="left"
          customColor={custom}
          color={pointColor}
        />
      </Title>
      <Speaker>
        <Text type="Heading-4" text={speaker} align="left" />
        {file && (
          <DownloadButton>
            <System.DownloadButton fileUrl={file} type="noBg" />
          </DownloadButton>
        )}
      </Speaker>
    </Wrap>
  )
}

CardMd.defaultProps = {
  text: 'Button',
  type: 'primary-1',
  theme: 'light',
}

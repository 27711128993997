import * as React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import * as Icon from '../Icons'

const Wrap = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: relative;
`
const Switch = styled(motion.div)``
const Bg = styled(motion.div)`
  width: 120px;
  height: 72px;
  overflow: visible;
  background-color: #ffffff;
  border-radius: 50px;
  box-shadow: 0px 12px 36px 0px rgba(0, 0, 0, 0.08);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
const Dot = styled(motion.div)`
  width: 60px;
  height: 60px;
  overflow: visible;
  background-color: #ffffff;
  border-radius: 50%;
  box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`
const Round0 = styled(motion.div)`
  width: 15px;
  height: 15px;
  overflow: visible;
  background-color: #4e8ff8;
  border-radius: 50%;
  position: absolute;
  top: 39px;
  left: 39px;
`
const Round1 = styled(motion.div)`
  width: 32px;
  height: 32px;
  overflow: visible;
  background-color: #3eb360;
  border-radius: 50%;
  position: absolute;
  bottom: 22px;
  right: 47px;
`
const Triangle = styled(motion.div)`
  width: 50px;
  height: 48px;
  overflow: visible;
  position: absolute;
  top: 72px;
  left: 20px;
`
const Rectangle = styled(motion.div)`
  width: 40px;
  height: 40px;
  transform: rotate(-15deg);
  overflow: visible;
  background-color: #f14d3e;
  border-radius: 10px;
  position: absolute;
  top: 24px;
  right: 24px;
`
const shapeVariants = {
  active: {
    opacity: 1,
    scale: 1,
  },
  inactive: {
    opacity: 0,
    scale: 0.3,
  },
}

export function WorkshopSwitch() {
  return (
    <Wrap>
      <Switch>
        <Bg
          initial={{
            background: '#FFFFFF',
          }}
          animate={{
            background: '#0898FC',
          }}
          transition={{
            yoyo: Infinity,
            repeatDelay: 1.5,
          }}
        >
          <Dot
            initial={{
              left: 6,
            }}
            animate={{ left: 54 }}
            transition={{
              ease: 'easeOut',
              duration: 0.3,
              // type: 'spring',
              // damping: 12,
              yoyo: Infinity,
              repeatDelay: 1.5,
            }}
          />
        </Bg>
      </Switch>
      <Round0
        initial="inactive"
        variants={shapeVariants}
        animate="active"
        transition={{
          yoyo: Infinity,
          repeatDelay: 1.5,
        }}
      />
      <Rectangle
        initial="inactive"
        variants={shapeVariants}
        animate="active"
        transition={{
          yoyo: Infinity,
          repeatDelay: 1.5,
        }}
      />
      <Round1
        initial="inactive"
        variants={shapeVariants}
        animate="active"
        transition={{
          yoyo: Infinity,
          repeatDelay: 1.5,
        }}
      />
      <Triangle
        initial="inactive"
        variants={shapeVariants}
        animate="active"
        transition={{
          yoyo: Infinity,
          repeatDelay: 1.5,
        }}
      >
        <Icon.Shape2 fill="#FFC501" />
      </Triangle>
    </Wrap>
  )
}

WorkshopSwitch.defaultProps = {}

import * as React from 'react'

export function FocusTooltip() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="165" height="35">
      <path
        d="M 0 4 C 0 1.791 1.791 0 4 0 L 153 0 C 155.209 0 157 1.791 157 4 L 157 11 L 165 17 L 157 23 L 157 30 C 157 32.209 155.209 34 153 34 L 4 34 C 1.791 34 0 32.209 0 30 Z"
        fill="rgba(52,121,255,1)"
      />
    </svg>
  )
}

import * as React from 'react'
import { useContext } from 'react'
import styled, { css, ThemeContext } from 'styled-components'
import { motion, useViewportScroll } from 'framer-motion'
import { themes } from '../../base'
import * as Icon from '../../design-system/components/Icons'

const Wrap = styled.div`
  position: sticky;
  top: ${60 + 72}px;
  width: 172px;
  left: 100px;
  margin-top: 72px;
  /* item fonts */
  font-size: 20px;
  font-weight: 700;
  ${props =>
    props.selectedTheme &&
    css`
      font-family: ${props.selectedTheme.font.en.type1};
      @media (max-width: ${props.selectedTheme.breakpoint.tablet}px) {
        display: none;
      }
    `}
`

const Foucus = styled(motion.div)`
  width: 200px;
  height: 34px;
  position: absolute;
`
const FoucusText = styled.div`
  position: absolute;
  line-height: 34px;
  color: #ffffff;
`
const FoucusIcon = styled.div`
  position: absolute;
  left: -12px;
`
const Item = styled.div`
  line-height: 48px;
  ${props =>
    props.selectedTheme &&
    css`
      color: ${props.selectedTheme.color.primaryText};
    `}
`

const menuItems = [
  'Having Fun',
  'Talks',
  'Show-and-tell',
  'Workshop',
  'Time Table',
  'Location',
  // 'Framer Korea',
  // 'Sponsored by',
]

function getProgress(scroller, focusPoint) {
  const children = scroller.childNodes
  const middle = window.innerHeight * focusPoint
  let prevBottom = children[0].getBoundingClientRect().bottom
  for (let i = 1; i < children.length; i++) {
    const { top, bottom } = children[i].getBoundingClientRect()
    const breakpoint = (prevBottom + top) / 2
    if (middle < breakpoint) {
      return i - 1
    }
    prevBottom = bottom
  }
  return children.length - 1
}

function useCurrentStep() {
  const [progress, setProgress] = React.useState(0)
  const focusPoint = 0.1
  React.useEffect(() => {
    const scroller = document.querySelector('.scroller')
    function onScroll() {
      const newProgress = getProgress(scroller, focusPoint)
      setProgress(newProgress)
    }
    document.addEventListener('scroll', onScroll)
    return () => {
      document.removeEventListener('scroll', onScroll)
    }
  }, [])
  return progress
}

export function LNB({ theme, ...props }) {
  const selectedTheme = useContext(ThemeContext) || themes[theme]
  const currentStep = useCurrentStep()
  console.log('currentStep', currentStep)

  return (
    <Wrap selectedTheme={selectedTheme}>
      <Foucus
        animate={{
          y: currentStep > 5 ? 5 * 50 : currentStep * 50,
        }}
        transition={{ ease: 'linear', duration: 0.2 }}
      >
        <FoucusIcon>
          <Icon.FocusTooltip />
        </FoucusIcon>
        <FoucusText>
          {currentStep > 5 ? menuItems[5] : menuItems[currentStep]}
        </FoucusText>
      </Foucus>
      {menuItems.map((item, id) => (
        <Item key={id} selectedTheme={selectedTheme}>
          {item}
        </Item>
      ))}
    </Wrap>
  )
}

LNB.defaultProps = {
  theme: 'light',
}

import * as React from 'react'

export function ArrowTop() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <g transform="translate(0.807 0)">
        <path
          d="M 0 9.192 L 9.192 0 L 11.314 2.121 L 2.121 11.314 Z"
          fill="rgb(255,255,255)"
        />
        <path
          d="M 7.093 2.121 L 9.214 0 L 18.407 9.192 L 16.285 11.314 Z"
          fill="rgb(255,255,255)"
        />
        <path
          d="M 7.693 2.015 L 10.693 2 L 10.693 19.985 L 7.693 20 Z"
          fill="rgb(255,255,255)"
        />
      </g>
    </svg>
  )
}

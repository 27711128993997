import * as React from 'react'
import { useContext } from 'react'
import styled, { css, ThemeContext } from 'styled-components'
import { motion } from 'framer-motion'
import { Text } from '../Text'
import { Spacing } from '../Spacing'
import { themes } from '../../../base'
import { TimeBoxL, TimeBoxR } from '../Icons/TimeBox'

export function TimeBoxCenter({ title, duration, children }) {
  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        margin: '0 auto',
      }}
    >
      <div style={{ flexShrink: 0 }}>
        <div style={{ position: 'relative' }}>
          <div
            style={{
              position: 'absolute',
              marginLeft: 41,
              marginTop: 48,
              zIndex: 10,
            }}
          >
            <Text
              type="Heading-3"
              text={title}
              style={{ color: 'white', display: 'block' }}
            />
            <Text
              type="Heading-3"
              text={duration}
              style={{ color: 'white', marginTop: 2 }}
            />
          </div>
          <div style={{ position: 'relative' }}>{children}</div>
        </div>
      </div>
    </div>
  )
}

export function TimeBoxLeft({ title, duration, fill }) {
  return (
    <div
      style={{
        position: 'absolute',
        left: 70,
        margin: '0 auto',
      }}
    >
      <div style={{ flexShrink: 0 }}>
        <div style={{ position: 'relative' }}>
          <div
            style={{
              position: 'absolute',
              marginLeft: 41,
              marginTop: 48,
              zIndex: 10,
            }}
          >
            <Text
              type="Heading-3"
              text={title}
              style={{ color: 'white', display: 'block' }}
            />
            <Text
              type="Heading-3"
              text={duration}
              style={{ color: 'white', marginTop: 2 }}
            />
          </div>
          <div style={{ position: 'relative' }}>
            <TimeBoxL fill={fill} />
          </div>
        </div>
      </div>
    </div>
  )
}

export function TimeBoxRight({ title, duration, fill }) {
  return (
    <div
      style={{
        position: 'absolute',
        right: 100,
        margin: '0 auto',
      }}
    >
      <div style={{ flexShrink: 0 }}>
        <div style={{ position: 'relative' }}>
          <div
            style={{
              position: 'absolute',
              marginLeft: 41,
              marginTop: 48,
              zIndex: 10,
            }}
          >
            <Text
              type="Heading-3"
              text={title}
              style={{ color: 'white', display: 'block' }}
            />
            <Text
              type="Heading-3"
              text={duration}
              style={{ color: 'white', marginTop: 2 }}
            />
          </div>
          <div style={{ position: 'relative' }}>
            <TimeBoxR fill={fill} />
          </div>
        </div>
      </div>
    </div>
  )
}

TimeBoxCenter.defaultProps = {
  title: 'title',
  duration: 'duration',
}

TimeBoxLeft.defaultProps = {
  title: 'title',
  duration: 'duration',
  fill: '#AAA',
}

TimeBoxRight.defaultProps = {
  title: 'title',
  duration: 'duration',
  fill: '#AAA',
}

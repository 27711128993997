import * as React from 'react'
import styled, { css, ThemeContext } from 'styled-components'
import './fonts.css'
import { useContext } from 'react'
import { themes } from '../../base'

export function Text({
  className,
  text,
  align,
  width,
  type,
  color,
  theme,
  customColor,
  style,
  display,
  ...props
}) {
  const selectedTheme = useContext(ThemeContext) || themes[theme]
  const locale =
    typeof text === 'string' ? (text.match(/[A-Za-z0-9]/) ? 'en' : 'ko') : 'en' // check the text is en or ko

  const P = styled.p`
    width: ${width}px;
    display: ${display};
    text-align: ${align};
    ${props =>
      props.type === 'Heading-1' &&
      css`
        font-family: ${selectedTheme.font[locale].type1};
        font-size: 36px;
        font-weight: 800;
        line-height: 1.2;
        color: ${selectedTheme.color.primaryText};
        @media (max-width: 414px) {
          font-size: 28px;
        }
      `}
    ${props =>
      props.type === 'Heading-2' &&
      css`
        font-family: ${selectedTheme.font[locale].type1};
        font-weight: 700;
        font-size: 26px;
        line-height: 1.2;

        color: ${selectedTheme.color.primaryText};
        @media (max-width: 414px) {
          font-size: 22px;
        }
      `}
      ${props =>
        props.type === 'Heading-3' &&
        css`
          font-family: ${selectedTheme.font[locale].type1};
          font-weight: 700;
          font-size: 24px;
          line-height: 1.3;
          color: ${selectedTheme.color.primaryText};
          @media (max-width: 414px) {
            font-size: 22px;
          }
        `}
        ${props =>
          props.type === 'Heading-4' &&
          css`
            font-family: ${selectedTheme.font[locale].type1};
            font-weight: 500;
            font-size: 16px;
            line-height: 1.5;
            color: ${selectedTheme.color.secondaryText};
          `}
      ${props =>
        props.type === 'Body' &&
        css`
          font-family: ${selectedTheme.font[locale].type1};
          font-weight: 200;
          font-size: 18px;
          line-height: 1.4;
          color: ${selectedTheme.color.primaryText};
        `}
    ${props =>
      props.type === 'Button' &&
      css`
        font-family: ${selectedTheme.font[locale].type1};
        font-weight: 700;
        font-size: 20px;
        line-height: 1.5;
        color: ${selectedTheme.color.buttonText};
      `}
    ${props =>
      props.type === 'Title-Detail' &&
      css`
        font-family: ${selectedTheme.font[locale].type1};
        font-size: 20px;
        font-weight: 700;
        line-height: 1.2;
        color: ${selectedTheme.color.secondaryText};
        @media (max-width: ${selectedTheme.breakpoint.mobile}px) {
          font-size: 20px;
        }
      `}
    ${props =>
      props.type === 'Header-Text' &&
      css`
        font-family: ${selectedTheme.font[locale].type1};
        color: ${selectedTheme.color.headerText};
        font-size: 16px;
        font-weight: 300;
      `}
    ${props =>
      props.type === 'Popup-Badge' &&
      css`
        font-family: ${selectedTheme.font[locale].type1};
        font-size: 20px;
        font-weight: 800;
        color: ${selectedTheme.color.framerPurple};
        @media (max-width: ${selectedTheme.breakpoint.mobile}px) {
          font-size: 18px;
        }
      `}
    ${props =>
      props.type === 'Popup-Title' &&
      css`
        font-family: ${selectedTheme.font[locale].type1};
        font-size: 32px;
        font-weight: 800;
        line-height: 1.3;
        color: ${selectedTheme.color.primaryText};
        @media (max-width: ${selectedTheme.breakpoint.mobile}px) {
          font-size: 22px;
          font-weight: 700;
        }
      `}
    ${props =>
      props.type === 'Popup-SubTitle' &&
      css`
        font-family: ${selectedTheme.font[locale].type1};
        font-size: 18px;
        font-weight: 600;
        line-height: 1.3;
        color: ${selectedTheme.color.headerText};
        @media (max-width: ${selectedTheme.breakpoint.mobile}px) {
          font-size: 14px;
          font-weight: 500;
        }
      `}
    ${props =>
      props.type === 'Popup-Body' &&
      css`
        font-family: ${selectedTheme.font[locale].type1};
        font-size: 18px;
        font-weight: 500;
        line-height: 1.3;
        color: ${selectedTheme.color.primaryText};
        @media (max-width: ${selectedTheme.breakpoint.mobile}px) {
          font-size: 14px;
        }
      `}
    ${props =>
      props.type === 'Popup-SummaryTitle' &&
      css`
        font-family: ${selectedTheme.font[locale].type1};
        font-size: 24px;
        font-weight: 700;
        line-height: 1.3;
        color: ${selectedTheme.color.framerPurple};
        @media (max-width: ${selectedTheme.breakpoint.mobile}px) {
          font-size: 18px;
        }
      `}

    /* custom text color options */
    color: ${customColor && color};
    
  `
  return (
    <P
      className={className}
      type={type}
      dangerouslySetInnerHTML={{ __html: text }}
      style={style}
    />
  )
}

Text.defaultProps = {
  text: 'Type Text',
  align: 'left',
  display: 'inline-block',
  type: 'Heading-1',
  height: 36,
  theme: 'light',
  customColor: false,
  color: themes.light.color.primaryText, // default custom color value from light theme
}

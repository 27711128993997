import * as React from 'react'

export function Shape2({ fill }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 478 440">
      <path
        d="M 156.645 75.47 C 175.865 42.012 224.135 42.012 243.355 75.47 L 356.969 273.242 C 376.118 306.575 352.055 348.148 313.613 348.148 L 86.387 348.148 C 47.945 348.148 23.882 306.575 43.031 273.242 Z"
        transform="translate(39 46) rotate(-345 200 174.074)"
        fill={fill}
      />
    </svg>
  )
}

import * as React from 'react'

export function Location({ fill }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 20">
      <g>
        <path d="M 0 0 L 15 0 L 15 20 L 0 20 Z" fill="transparent" />
        <path
          d="M 7.5 1 C 11.09 1 14 3.968 14 7.63 C 14 8.945 13.625 10.17 12.978 11.201 L 12.982 11.201 L 7.916 18.774 C 7.823 18.913 7.667 18.997 7.5 18.997 C 7.333 18.997 7.177 18.913 7.084 18.774 L 2.204 11.474 C 2.153 11.401 2.103 11.327 2.054 11.252 L 2.02 11.2 C 1.351 10.13 0.997 8.892 1 7.63 C 1 3.968 3.91 1 7.5 1 Z M 7.5 5.42 C 6.303 5.42 5.333 6.41 5.333 7.63 C 5.333 8.85 6.303 9.84 7.5 9.84 C 8.697 9.84 9.667 8.85 9.667 7.63 C 9.667 6.41 8.697 5.42 7.5 5.42 Z"
          fill={fill}
        />
      </g>
    </svg>
  )
}

import * as React from 'react'
import { useContext, useState } from 'react'
import styled, { css, ThemeContext } from 'styled-components'
import { motion } from 'framer-motion'
import { Text } from '../Text'
import { Spacing } from '../Spacing'
import { themes } from '../../../base'

export function CardSm({
  children,
  theme,
  height,
  background,
  title,
  startTime,
  endTime,
  duration,
  ...props
}) {
  const selectedTheme = useContext(ThemeContext) || themes[theme]

  const Wrap = styled.div`
    width: 100%;
    height: 96px;
    border-radius: 12px;
    border: none;
    background: ${background};
    position: relative;
    margin-left: auto;
    margin-right: auto;
  `
  const timeDisplay = `${startTime} - ${endTime}`

  return (
    <Wrap>
      <Spacing height="20" />
      <Spacing width="24" type="h" />
      <Text type="Heading-2" text={title} style={{ color: '#FFFFFF' }} />
      <Spacing height="4" />
      <Spacing width="24" type="h" />
      <Text type="Body" text={timeDisplay} style={{ color: '#FFFFFF' }} />
      <Text
        type="Body"
        text={duration}
        style={{ color: '#FFFFFF', opacity: 0.7, marginLeft: 18 }}
      />
    </Wrap>
  )
}

CardSm.defaultProps = {
  theme: 'light',
  background: 'linear-gradient(180deg, #E29FFF 0%, #CF5FFF 100%)',
}

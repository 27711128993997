import * as React from 'react'
import { useContext, useState } from 'react'
import styled, { css, ThemeContext } from 'styled-components'
import { motion } from 'framer-motion'
import { Text } from '../Text'
import { Spacing } from '../Spacing'
import { themes } from '../../../base'

export function CardXL({ children, theme, height, ...props }) {
  const selectedTheme = useContext(ThemeContext) || themes[theme]

  const Wrap = styled.div`
    width: 100%;
    height: ${height ? height + 'px' : '100%'};
    border-radius: 12px;
    border: none;
    background: ${selectedTheme.color.contentBg};
    position: relative;
    margin-left: auto;
    margin-right: auto;
  `

  return <Wrap>{children}</Wrap>
}

CardXL.defaultProps = {
  theme: 'light',
}

import * as React from 'react'
import { useContext } from 'react'
import styled, { css, ThemeContext } from 'styled-components'
import { useIntl, Link, FormattedMessage } from 'gatsby-plugin-intl'
import { motion } from 'framer-motion'
import { themes } from '../../../base'
import { CardXL, CardSm } from '../Card'
import { Spacing } from '../Spacing'
import { Text } from '../Text'
import { TimeBoxA, TimeBoxB, TimeBoxC, TimeBoxD } from '../Icons/TimeBox'
import * as System from '../../../design-system'
import useHover from './useHover'

const TimetableWebWrapper = styled.div`
  @media (max-width: 1080px) {
    display: none;
  }
`

const TimetableMobileWrapper = styled.div`
  display: none;
  @media (max-width: 1080px) {
    display: block;
  }
`

const TimeBar = styled.div.attrs(props => ({
  column: props.column,
  bg: props.bg,
}))`
  grid-column: ${props => props.column};
  background: ${props => props.bg};
  height: 18px;
  border-radius: 10px;
  margin-top: auto;
  margin-bottom: auto;
`

const TimeBoxIcon = styled(motion.div)`
  cursor: pointer;
  /* extra area for hover */
  height: 150px;
  margin-top: -56px;
`
const TimeBoxIconHover = ({ children, callback, animate }) => {
  const [hoverRef, isHovered] = useHover()
  React.useEffect(() => {
    if (isHovered && callback) callback()
  }, [isHovered])
  return (
    <TimeBoxIcon
      ref={hoverRef}
      initial={{
        opacity: 0,
      }}
      animate={animate}
    >
      <System.Spacing height={56} />
      {children}
    </TimeBoxIcon>
  )
}

export function TimeTableWeb() {
  const intl = useIntl()
  const [active, setActive] = React.useState(0)

  return (
    <TimetableWebWrapper>
      <CardXL height="449">
        <div
          style={{
            marginLeft: 60,
            marginRight: 60,
          }}
        >
          <Spacing height="50" />
          <Text
            type="Heading-2"
            text={intl.formatMessage({ id: 'home.timetable.date' })}
          />
          <Spacing height="32" />
          <System.Divider />
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(6, 1fr)',

              paddingTop: 28,
              marginBottom: 32,
              // borderTop: '1px solid #dbdce0',
            }}
          >
            <Text type="Heading-2" text="1:00" />
            <Text type="Heading-2" text="1:30" />
            <Text type="Heading-2" text="2:00" />
            <Text type="Heading-2" text="2:30" />
            <Text type="Heading-2" text="3:00" />
            <Text type="Heading-2" text="3:30" />
          </div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(18, 53.33px)',
              height: 38,
              // border: '1px solid #222',
            }}
          >
            <TimeBar column="1">
              <TimeBar
                column="1"
                bg="linear-gradient(90deg, #E3A2FF 0%, #CE5EFF 100%)"
              />
              <Spacing height="38" />
              <TimeBoxIconHover
                animate={{
                  opacity: active === 0 ? 1 : 0,
                }}
                callback={() => setActive(0)}
              >
                <System.TimeBoxLeft
                  title={intl.formatMessage({
                    id: 'home.timetable.content.t1.title',
                  })}
                  duration={intl.formatMessage({
                    id: 'home.timetable.content.t1.duration',
                  })}
                />
              </TimeBoxIconHover>
            </TimeBar>
            <TimeBar column="2/10">
              <div style={{ display: 'grid' }}>
                <TimeBar
                  column="1/3"
                  bg="linear-gradient(180deg, #B201FE 0%, #962DFF 100%)"
                />
                <TimeBar
                  column="3/5"
                  bg="linear-gradient(180deg, #B201FE 0%, #962DFF 100%)"
                />
                <TimeBar
                  column="5/7"
                  bg="linear-gradient(180deg, #B201FE 0%, #962DFF 100%)"
                />
                <TimeBar
                  column="7/9"
                  bg="linear-gradient(180deg, #B201FE 0%, #962DFF 100%)"
                />
              </div>
              <Spacing height="38" />
              <TimeBoxIconHover
                animate={{
                  opacity: active === 1 ? 1 : 0,
                }}
                callback={() => setActive(1)}
              >
                <System.TimeBoxCenter
                  title={intl.formatMessage({
                    id: 'home.timetable.content.t2.title',
                  })}
                  duration={intl.formatMessage({
                    id: 'home.timetable.content.t2.duration',
                  })}
                >
                  <TimeBoxA />
                </System.TimeBoxCenter>
              </TimeBoxIconHover>
            </TimeBar>

            <TimeBar column="10">
              <TimeBar column="1" bg="#aaa" />
              <Spacing height="38" />
              <TimeBoxIconHover
                animate={{
                  opacity: active === 2 ? 1 : 0,
                }}
                callback={() => setActive(2)}
              >
                <System.TimeBoxCenter
                  title={intl.formatMessage({
                    id: 'home.timetable.content.t3.title',
                  })}
                  duration={intl.formatMessage({
                    id: 'home.timetable.content.t3.duration',
                  })}
                >
                  <TimeBoxB />
                </System.TimeBoxCenter>
              </TimeBoxIconHover>
            </TimeBar>
            <TimeBar column="11/15">
              <TimeBar
                column="1/4"
                bg="linear-gradient(90deg, #4A0CDB 0%, #8302FF 100%)"
              />
              <Spacing height="38" />
              <TimeBoxIconHover
                animate={{
                  opacity: active === 3 ? 1 : 0,
                }}
                callback={() => setActive(3)}
              >
                <System.TimeBoxCenter
                  title={intl.formatMessage({
                    id: 'home.timetable.content.t4.title',
                  })}
                  duration={intl.formatMessage({
                    id: 'home.timetable.content.t4.duration',
                  })}
                >
                  <TimeBoxC />
                </System.TimeBoxCenter>
              </TimeBoxIconHover>
            </TimeBar>

            <TimeBar column="15/17">
              <TimeBar
                column="1/2"
                bg="linear-gradient(90deg, #3479FF 0%, #4401FF 100%)"
              />
              <Spacing height="38" />
              <TimeBoxIconHover
                animate={{
                  opacity: active === 4 ? 1 : 0,
                }}
                callback={() => setActive(4)}
              >
                <System.TimeBoxCenter
                  title={intl.formatMessage({
                    id: 'home.timetable.content.t5.title',
                  })}
                  duration={intl.formatMessage({
                    id: 'home.timetable.content.t5.duration',
                  })}
                >
                  <TimeBoxD />
                </System.TimeBoxCenter>
              </TimeBoxIconHover>
            </TimeBar>

            <TimeBar column="17/19">
              <TimeBar
                column="1/2"
                bg="linear-gradient(270deg, #3479ff 0%, rgba(7,189,248,1) 100%)"
              />
              <Spacing height="38" />
              <TimeBoxIconHover
                animate={{
                  opacity: active === 5 ? 1 : 0,
                }}
                callback={() => setActive(5)}
              >
                <System.TimeBoxRight
                  title={intl.formatMessage({
                    id: 'home.timetable.content.t6.title',
                  })}
                  duration={intl.formatMessage({
                    id: 'home.timetable.content.t6.duration',
                  })}
                />
              </TimeBoxIconHover>
            </TimeBar>
          </div>
        </div>
      </CardXL>
    </TimetableWebWrapper>
  )
}

TimeTableWeb.defaultProps = {}

export function TimeTableMobile() {
  const intl = useIntl()
  return (
    <TimetableMobileWrapper>
      <CardXL>
        <Spacing height="22" />
        <div
          style={{
            marginLeft: 22,
            marginRight: 22,
          }}
        >
          <Text
            type="Heading-2"
            text={intl.formatMessage({ id: 'home.timetable.date' })}
          />
        </div>
        <Spacing height="20" />

        <div
          style={{
            display: 'grid',
            gridGap: 16,
            marginLeft: 22,
            marginRight: 22,
          }}
        >
          <CardSm
            title={intl.formatMessage({
              id: 'home.timetable.content.t1.title',
            })}
            startTime={intl.formatMessage({
              id: 'home.timetable.content.t1.startTime',
            })}
            endTime={intl.formatMessage({
              id: 'home.timetable.content.t1.endTime',
            })}
            duration={intl.formatMessage({
              id: 'home.timetable.content.t1.duration',
            })}
            background="linear-gradient(180deg, #E29FFF 0%, #CF5FFF 100%)"
          />
          <CardSm
            title={intl.formatMessage({
              id: 'home.timetable.content.t2.title',
            })}
            startTime={intl.formatMessage({
              id: 'home.timetable.content.t2.startTime',
            })}
            endTime={intl.formatMessage({
              id: 'home.timetable.content.t2.endTime',
            })}
            duration={intl.formatMessage({
              id: 'home.timetable.content.t2.duration',
            })}
            background="linear-gradient(180deg, #b201fe 0%, rgba(150,45,255,1) 100%)"
          />
          <CardSm
            title={intl.formatMessage({
              id: 'home.timetable.content.t3.title',
            })}
            startTime={intl.formatMessage({
              id: 'home.timetable.content.t3.startTime',
            })}
            endTime={intl.formatMessage({
              id: 'home.timetable.content.t3.endTime',
            })}
            duration={intl.formatMessage({
              id: 'home.timetable.content.t3.duration',
            })}
            background="#5f6368"
          />
          <CardSm
            title={intl.formatMessage({
              id: 'home.timetable.content.t4.title',
            })}
            startTime={intl.formatMessage({
              id: 'home.timetable.content.t4.startTime',
            })}
            endTime={intl.formatMessage({
              id: 'home.timetable.content.t4.endTime',
            })}
            duration={intl.formatMessage({
              id: 'home.timetable.content.t4.duration',
            })}
            background="linear-gradient(180deg, #8400ff 0%, rgba(95,0,255,1) 100%)"
          />
          <CardSm
            title={intl.formatMessage({
              id: 'home.timetable.content.t5.title',
            })}
            startTime={intl.formatMessage({
              id: 'home.timetable.content.t5.startTime',
            })}
            endTime={intl.formatMessage({
              id: 'home.timetable.content.t5.endTime',
            })}
            duration={intl.formatMessage({
              id: 'home.timetable.content.t5.duration',
            })}
            background="linear-gradient(180deg, #4401ff 0%, rgba(52,121,255,1) 100%)"
          />
          <CardSm
            title={intl.formatMessage({
              id: 'home.timetable.content.t6.title',
            })}
            startTime={intl.formatMessage({
              id: 'home.timetable.content.t6.startTime',
            })}
            endTime={intl.formatMessage({
              id: 'home.timetable.content.t6.endTime',
            })}
            duration={intl.formatMessage({
              id: 'home.timetable.content.t6.duration',
            })}
            background="linear-gradient(179.99999999999997deg, #3479ff 0%, rgba(7,189,248,1) 100%);"
          />
        </div>
        <Spacing height="22" />
      </CardXL>
    </TimetableMobileWrapper>
  )
}

TimeTableMobile.defaultProps = {}

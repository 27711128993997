import * as React from 'react'
import { useContext } from 'react'
import styled, { css, ThemeContext } from 'styled-components'

import { themes } from '../../../base'

export function Divider({ showOnMobile, theme, ...props }) {
  const selectedTheme = useContext(ThemeContext) || themes[theme]

  const Divide = styled.div`
    border-top: 1px solid ${selectedTheme.color.divider};
    @media (max-width: 1080px) {
      display: ${showOnMobile ? 'block' : 'none'};
    }
  `

  return <Divide />
}

Divider.defaultProps = {
  theme: 'light',
  showOnMobile: false,
}

import * as React from 'react'
import { useContext } from 'react'
import styled, { css, ThemeContext } from 'styled-components'
import { motion } from 'framer-motion'
import { Text } from '../Text'
import { Spacing } from '../Spacing'
import { themes } from '../../../base'

const Link = styled.a`
  text-decoration: none;
  }
`

export function ProfileCard({
  image,
  nickname,
  theme,
  name,
  role,
  link,
  ...props
}) {
  const selectedTheme = useContext(ThemeContext) || themes[theme]

  return (
    <div>
      <Link href={link} target="_blank" rel="noopener noreferrer">
        <img
          src={image}
          alt="Profile"
          style={{ width: 60, height: 60, borderRadius: 60 }}
        />
        <Spacing height="8" />
        <Text
          type="Heading-4"
          text={name}
          style={{
            color: selectedTheme.color.primaryText,
          }}
        />
        <Text
          type="Heading-4"
          text=" / "
          style={{
            color: selectedTheme.color.primaryText,
          }}
        />
        <Text
          type="Heading-4"
          text={nickname}
          style={{
            color: selectedTheme.color.primaryText,
          }}
        />
        <Text type="Heading-4" text={role} style={{ display: 'block' }} />
        <Spacing height="8" />
      </Link>
    </div>
  )
}

ProfileCard.defaultProps = {
  image: 'http://placekitten.com/60/60',
  name: 'Jinjae Lee',
  role: 'Content+Development',
  theme: 'light',
  nickname: 'CSS Hater',
  link: 'https://jinbread.works',
}

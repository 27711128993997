import * as React from 'react'

export function CloseButton() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
      <g>
        <path
          d="M 0 24 C 0 10.745 10.745 0 24 0 C 37.255 0 48 10.745 48 24 C 48 37.255 37.255 48 24 48 C 10.745 48 0 37.255 0 24 Z"
          fill="rgb(51,51,51)"
          opacity="0.7"
        />
        <g transform="translate(14.807 14.81)">
          <path
            d="M 0 16.264 L 16.263 0.001 L 18.385 2.122 L 2.121 18.385 Z"
            fill="rgb(255,255,255)"
          />
          <path
            d="M 0.003 2.121 L 2.124 0 L 18.387 16.263 L 16.266 18.385 Z"
            fill="rgb(255,255,255)"
          />
        </g>
      </g>
    </svg>
  )
}

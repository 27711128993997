import * as React from 'react'
import styled, { css } from 'styled-components'

const colors = {
  error: 'hsla(0, 100%, 60%, 1)',
  placeholder: 'hsla(204, 100%, 50%, 1)',
  border: 'hsla(204, 100%, 50%, 0.32)',
  placeholderLight: 'rgba(0, 153, 255, 0.25)',
}

const Wrap = styled.div<{ color?: string; striped?: boolean }>`
  max-width: 100%;
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  word-wrap: normal;
  display: grid;
  align-content: center;
  gap: 10px;
  ${({ color }) => css`
    color: ${color};
    border: ${`2px dashed ${color.replace('1)', '0.32)')}`};
  `}
  ${({ striped }) =>
    striped &&
    css`
      background-image: ${`linear-gradient(135deg, ${colors.placeholderLight} 5.56%, transparent 5.56%, transparent 50%, ${colors.placeholderLight} 50%, ${colors.placeholderLight} 55.56%, transparent 55.56%, transparent 100%)`};
      background-size: 12.73px 12.73px;
    `}
`
const Title = styled.h5``
const Label = styled.p``

export const PlaceholderState = ({ title, label, error, striped, ...rest }) => {
  const color = error ? colors.error : colors.placeholder
  return (
    <Wrap color={color} striped={striped}>
      <Title>{title}</Title>
      <Label>{label}</Label>
    </Wrap>
  )
}

PlaceholderState.defaultProps = {
  title: 'title',
  label: 'label',
  error: false,
  striped: false,
}

import * as React from 'react'

export function FramerLogoGrad({ rotate, fillFrom, fillTo }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 47">
      <g>
        <defs>
          <linearGradient
            id="ide6oIu5l2Pg-289338166"
            gradientTransform={rotate}
          >
            <stop offset="0" stopColor={fillFrom} stopOpacity="1" />
            <stop offset="1" stopColor={fillTo} stopOpacity="1" />
          </linearGradient>
        </defs>
        <path
          d="M 0.5 0 L 31.5 0 L 31.5 15.399 L 16 15.399 Z M 0.5 15.399 L 16 15.399 L 31.5 30.797 L 16 30.797 L 16 46.196 L 0.5 30.797 Z"
          fill="url(#ide6oIu5l2Pg-289338166)"
        />
      </g>
    </svg>
  )
}
